import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Candidate } from 'types/jobPipeline';
import JobCard from '../components/JobCard';

const SectionContainer = styled.div<{ isDraggingOver: boolean }>`
    margin-bottom: 16px;
    padding: 24px;
    margin: 10px;
    background-color: ${props => props.isDraggingOver ? '#e0e6f8' : '#F0F3FA'}; 
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
`;

const SectionTitle = styled.h5`
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
`;

interface SectionProps {
    sectionId: string;
    title: string;
    candidates: Candidate[];
    onFavoriteToggle: (id: string) => void;
    onMenuAction: (action: string, id: string) => void;
}

const Section: React.FC<SectionProps> = ({ sectionId, title, candidates, onFavoriteToggle, onMenuAction }) => {
    return (
        <Droppable droppableId={sectionId}>
            {(provided, snapshot) => (
                <SectionContainer
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    {...provided.droppableProps}
                >
                    <SectionTitle>{title}</SectionTitle>
                    {candidates.map((candidate, index) => (
                        <JobCard
                            key={candidate.candidateId}
                            candidate={candidate}
                            index={index}
                            onFavoriteToggle={onFavoriteToggle}
                            onMenuAction={onMenuAction}
                        />
                    ))}
                    {provided.placeholder}
                </SectionContainer>
            )}
        </Droppable>
    );
};

export default Section;