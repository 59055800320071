import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import OuterDiv from "components/Common/OuterDivComponent";
import JobPipelineWrapper from "containers/JobPipeline/containers/JobPipelineComponent";
import { useMenuVisibility } from "providers/menuVisibilityProvider";
import { RootState } from "store";

const Wrapper = styled.div`
`;

export const JobPipeline = () => {
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const { isMenuVisible } = useMenuVisibility()!;
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get("jobId");

    return (
        <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
            <Wrapper>
                {jobId && <JobPipelineWrapper expertId={expertId} jobId={jobId} />}
            </Wrapper>
        </OuterDiv>
    );
};