/** @jsxImportSource @emotion/react */
import { Card } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Chat, Record, Tools } from 'styled-icons/bootstrap';
import { History, Note } from 'styled-icons/boxicons-regular';
import { useJobPipeline } from '../contexts/JobPipelineContext';
import { compensationOptions } from 'pages/B2B/constants';
import { currencyList, employmentTypes } from 'containers/HiringManager/Jobs.util';

const JobDetailsContainer = styled(Card)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin: 0px 12px;
    border-bottom: 1px solid #E0E0E0;
`;

const JobInfo = styled.div`
    display: flex;
    gap: 32px;
`;

const JobInfoItem = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 10px;
    span:first-child {
        font-weight: 500;
        color: #757575;  
    }

    span:last-child {
        font-weight: 600;
        color: #333333;  
    }
`;

const JobInfoItemSalary = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    span {
        font-weight: 600 !important;
        color: #333333 !important;  
    }
`;

const JobActions = styled.div`
    display: flex;
    gap: 12px;  
    align-items: center;

    svg {
        width: 16px;  
        height: 16px;
        color: #2979FF;  
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #1a5fb4;  
        }
    }
`;

const JobDetails: React.FC = () => {
    const { currentJob } = useJobPipeline();

    if (!currentJob) return null;

    const currency = currencyList.find(c => c.code === currentJob.currency);

    return (
        <JobDetailsContainer>
            <JobInfo>
                <JobInfoItem>
                    <span>Company:</span>
                    <span>{currentJob.companyInfo?.companyName || ""}</span>
                </JobInfoItem>
                <JobInfoItem>
                    {currentJob.companyInfo?.companyLogo ? <img src={currentJob.companyInfo?.companyLogo} alt={currentJob.companyInfo?.companyName} /> : <span>""</span>}
                    <span>{currentJob.expertInfo?.fullname}</span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Job Type:</span>
                    <span>{currentJob.jobType}</span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Location:</span>
                    <span>{currentJob.locations?.join(", ")}</span>
                </JobInfoItem>
                {currentJob.jobType === "remote" && <JobInfoItem>
                    <span>Time Zone:</span>
                    <span>{currentJob.timezone}</span>
                </JobInfoItem>}
                <JobInfoItem>
                    <span>Employment Type:</span>
                    <span>{employmentTypes.find(e => e.key === currentJob.employmentType)?.value}</span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Salary:</span>
                    <JobInfoItemSalary>
                        <span>{(currency?.icon || "") + currentJob.minSalary}</span>
                        <span>-</span>
                        <span>{(currency?.icon || "") + currentJob.maxSalary}</span>
                    </JobInfoItemSalary>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Compensation:</span>
                    <span>{compensationOptions.find(c => c.key === currentJob.equity)?.label}</span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Open Positions:</span>
                    <span>{currentJob.openPositions}</span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Rejected:</span>
                    <span>{currentJob.rejectedCandidates}</span>
                </JobInfoItem>
            </JobInfo>

            <JobActions>
                <Tools />
                <Note />
                <History />
                <Chat />
                <Record />
            </JobActions>
        </JobDetailsContainer>
    );
};

export default JobDetails;