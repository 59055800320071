/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Card, IconButton, Avatar } from '@mui/material';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { DragIndicator } from 'styled-icons/material';
import { MoreVertical } from 'styled-icons/evaicons-solid';
import { Star, Person } from 'styled-icons/material';
import { ChatDotsFill } from 'styled-icons/bootstrap';
import styled from 'styled-components';
import { Candidate } from 'types/jobPipeline';
import { useJobPipeline } from '../contexts/JobPipelineContext';

interface JobCardProps {
    candidate: Candidate;
    index: number;
    onFavoriteToggle: (id: string) => void;
    onMenuAction: (action: string, id: string) => void;
}

const CardContainer = styled(Card)`
    margin-bottom: 8px;
    padding: 10px;
    border: 1px solid #E0E0E0;
    box-shadow: none;
    &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
`;

const CandidateHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

const CandidateNameSection = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const CandidateImage = styled(Avatar) <{ background: string }>`
    width: 40px;
    height: 40px;
    background-color: ${props => props.background + "!important"};
    color: #171717 !important;
    font-weight: 600;
    opacity: 0.7;
`;

const CandidateName = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 600;
    color: #333333;
    font-size: 12px;
`;

const LastUpdated = styled.span`
    font-weight: 400;
    color: #757575;
    font-size: 12px;
`;

const StatsSection = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    justify-content: flex-end; 
    span {
        color: #333;
        font-size: 10px;
    }
    .tier {
        color: #2e7d32
    }
    .ai {
        color: #f57c00
    }
    .sme {
        color: #d32f2f
    }
    .score {
        color: #fbc02d
    }
    .completion {
        color: #0288d1
    }
`;

const CandidateTags = styled.div`
    display: flex;
    gap: 4px;
    margin: 10px 0;
`;

const Tag = styled.span<{ background: string }>`
    padding: 2px 4px;
    border-radius: 12px;
    background-color: ${props => `${props.background}CC`};  
    font-size: 8px;
    font-weight: 600;
    color: black;
    opacity: 0.8;
`;

const IncompleteTasksSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #757575;
    border-top: 1px solid #E0E0E0;
    padding-top: 8px;
`;

const AssignedProfiles = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const DragIcon = styled(DragIndicator)`
    color: #757575;
    width: 16px;
    height: 16px;
    cursor: grab;
`;

const StarIcon = styled(Star) <{ isFavorite: boolean }>`
    color: ${props => (props.isFavorite ? '#325CD6' : '#BDBDBD')};
    width: 16px;
    height: 16px;
`;

const MoreIcon = styled(MoreVertical)`
    color: #757575;
    width: 16px;
    height: 16px;
`;

const JobCard: React.FC<JobCardProps> = ({ candidate, index, onFavoriteToggle, onMenuAction }) => {
    const { handleActiveCandidateProfile } = useJobPipeline();
    const getInitials = (name: string) => {
        const nameParts = name.split(' ');
        const initials = nameParts.map(part => part[0]).join('');
        return initials.toUpperCase();
    };

    const handleCandidateProfile = (candidate: Candidate) => {
        handleActiveCandidateProfile(candidate);
    }

    return (
        <Draggable draggableId={candidate.candidateId} index={index}>
            {(provided) => (
                <CardContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <CandidateHeader>
                        <DragIcon />
                        <StatsSection>
                            {!!candidate.candidateResumeDetails?.companyTire && (
                                <>
                                    <span className="tier">Tier {candidate.candidateResumeDetails.companyTire}</span> |
                                </>
                            )}
                            {!!candidate.candidateEvaluationDetails?.aiEvaluationScore && (
                                <>
                                    <span className="ai">AI: {candidate.candidateEvaluationDetails.aiEvaluationScore}</span> |
                                </>
                            )}
                            {!!candidate.candidateEvaluationDetails?.subjectMatterExpertEvaluationScore && (
                                <>
                                    <span className="sme">SME: {candidate.candidateEvaluationDetails.subjectMatterExpertEvaluationScore}</span> |
                                </>
                            )}
                            {!!candidate.candidateEvaluationDetails?.coachEvaluationScore && (
                                <>
                                    <span className="sme">COACH: {candidate.candidateEvaluationDetails.coachEvaluationScore}</span> |
                                </>
                            )}
                            {!!candidate.candidateResumeDetails?.universityScore && (
                                <>
                                    <span className="sme">{candidate.candidateResumeDetails.universityScore}</span> |
                                </>
                            )}
                            {!!candidate.candidateResumeDetails?.jobMatchScore && (
                                <>
                                    <span className="score">{candidate.candidateResumeDetails.jobMatchScore}%</span> |
                                </>
                            )}
                            <IconButton onClick={() => onFavoriteToggle(candidate.candidateId)} size="small">
                                <StarIcon isFavorite={!!candidate.isFavorite} />
                            </IconButton>
                            <IconButton onClick={() => onMenuAction('open', candidate.candidateId)} size="small">
                                <MoreIcon color='#325CD6' />
                            </IconButton>
                        </StatsSection>
                    </CandidateHeader>

                    <CandidateHeader>
                        <CandidateNameSection>
                            <CandidateImage
                                src={candidate.image || ''}
                                background={candidate.image ? '' : '#BFD0FF'}
                            >
                                {!candidate.image && getInitials(candidate.fullname)}
                            </CandidateImage>
                            <CandidateName>
                                <span>{candidate.fullname}</span>
                                <LastUpdated>Last updated: {candidate.lastActivityAt}</LastUpdated>
                            </CandidateName>
                        </CandidateNameSection>
                    </CandidateHeader>

                    <CandidateTags>
                        {candidate.candidateStates.map(state => <Tag background={state.label ? "#f57c00" : "#4caf50"}>{state.label}</Tag>)}
                    </CandidateTags>

                    <IncompleteTasksSection>
                        <AssignedProfiles>
                            {candidate.assignedProfiles?.map((profile, index) => (
                                <CandidateImage
                                    key={index}
                                    src={profile.image || ''}
                                    background={profile.image || '#2979ff7a'}
                                    css={css`
                                        width: 16px;
                                        height: 16px;
                                    `}
                                >
                                    {!profile.image && getInitials(profile.fullname)}
                                </CandidateImage>
                            ))}
                            {!!candidate.incompleteTasks && <span>Incomplete tasks: {candidate.incompleteTasks}</span>}
                        </AssignedProfiles>
                        <div>
                            <IconButton size="small">
                                <ChatDotsFill color='#325CD6' size="16" />
                            </IconButton>
                            |
                            <IconButton size="small" onClick={() => handleCandidateProfile(candidate)}>
                                <Person color='#325CD6' size="16" />
                            </IconButton>
                        </div>
                    </IncompleteTasksSection>
                </CardContainer>
            )}
        </Draggable>
    );
};

export default JobCard;