import React, { createContext, useContext, useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { fetchCandidatesForJob, fetchJobsData, updateCandidateStatus } from 'services/jobPipeline';
import { Candidate, Job } from 'types/jobPipeline';

interface JobPipelineContextProps {
    currentJob: Job | null;
    talentPoolCandidates: Candidate[];
    coachClientEvaluationsCandidates: Candidate[];
    selectedCandidates: Candidate[];
    rejectedCandidates: Candidate[];
    activeTab: string;
    activeCandidateProfile?: Candidate;
    handleTabChange: (tab: string) => void;
    handleActiveCandidateProfile: (candidate?: Candidate) => void;
    handlePrevJob: () => void;
    handleNextJob: () => void;
    handleFavoriteToggle: (id: string) => void;
    handleMenuAction: (action: string, id: string) => void;
    handleOnDragEnd: (result: DropResult) => void;
}

const JobPipelineContext = createContext<JobPipelineContextProps | undefined>(undefined);

export const useJobPipeline = () => {
    const context = useContext(JobPipelineContext);
    if (!context) {
        throw new Error('useJobPipeline must be used within a JobPipelineProvider');
    }
    return context;
};

export const JobPipelineProvider: React.FC<{ children: React.ReactNode; jobId: string; expertId: string }> = ({ children, jobId, expertId }) => {
    const [currentJob, setCurrentJob] = useState<Job | null>(null);
    const [talentPoolCandidates, setTalentPoolCandidates] = useState<Candidate[]>([]);
    const [coachClientEvaluationsCandidates, setCoachClientEvaluationsCandidates] = useState<Candidate[]>([]);
    const [selectedCandidates, setSelectedCandidates] = useState<Candidate[]>([]);
    const [rejectedCandidates, setRejectedCandidates] = useState<Candidate[]>([]);
    const [activeTab, setActiveTab] = useState('Active');
    const [activeCandidateProfile, setActiveCandidateProfile] = useState<Candidate>();

    useEffect(() => {
        const loadJobs = async () => {
            try {
                const jobData = await fetchJobsData({ expertId, jobId });
                setCurrentJob(jobData.output);
            } catch (error) {
                console.error('Failed to load job data:', error);
            }
        };
        const loadCandidates = async () => {
            try {
                const {
                    talentPoolCandidates,
                    coachAndClientEvaluationsCandidates,
                    finalizeCandidates,
                    rejectedCandidates,
                } = await fetchCandidatesForJob({ jobId, expertId });

                setTalentPoolCandidates(talentPoolCandidates);
                setCoachClientEvaluationsCandidates(coachAndClientEvaluationsCandidates);
                setSelectedCandidates(finalizeCandidates);
                setRejectedCandidates(rejectedCandidates);
            } catch (error) {
                console.error('Failed to load candidates:', error);
            }
        };
        loadJobs();
        loadCandidates();
    }, [expertId, jobId]);

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    const handleActiveCandidateProfile = (candidate?: Candidate) => {
        setActiveCandidateProfile(candidate);
    }

    const handlePrevJob = () => {
        // Logic to go to the previous job
    };

    const handleNextJob = () => {
        // Logic to go to the next job
    };

    const handleFavoriteToggle = (id: string) => {
        // Logic for toggling favorite status
    };

    const handleMenuAction = (action: string, id: string) => {
        // Logic for handling menu actions
    };

    const handleOnDragEnd = async (result: DropResult) => {
        if (!result.destination) return;

        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceCandidates = getCandidatesBySectionId(source.droppableId);
            const destCandidates = getCandidatesBySectionId(destination.droppableId);

            const [movedCandidate] = sourceCandidates.splice(source.index, 1);
            destCandidates.splice(destination.index, 0, movedCandidate);

            updateCandidatesState(source.droppableId, sourceCandidates);
            updateCandidatesState(destination.droppableId, destCandidates);
            await updateCandidateStatus(
                movedCandidate.candidateId,
                jobId,
                expertId,
                destination.droppableId.toUpperCase()
            );
        } else {
            const candidates = getCandidatesBySectionId(source.droppableId);
            const [movedCandidate] = candidates.splice(source.index, 1);
            candidates.splice(destination.index, 0, movedCandidate);

            updateCandidatesState(source.droppableId, candidates);
        }
    };

    const getCandidatesBySectionId = (sectionId: string): Candidate[] => {
        switch (sectionId) {
            case 'talent_pool':
                return [...talentPoolCandidates];
            case 'interview_request':
                return [...coachClientEvaluationsCandidates];
            case 'finalized_candidate':
                return [...selectedCandidates];
            case 'rejected_candidates':
                return [...rejectedCandidates];
            default:
                return [];
        }
    };

    const updateCandidatesState = (sectionId: string, updatedCandidates: Candidate[]) => {
        switch (sectionId) {
            case 'talent_pool':
                setTalentPoolCandidates(updatedCandidates);
                break;
            case 'interview_request':
                setCoachClientEvaluationsCandidates(updatedCandidates);
                break;
            case 'finalized_candidate':
                setSelectedCandidates(updatedCandidates);
                break;
            case 'rejected_candidates':
                setRejectedCandidates(updatedCandidates);
                break;
            default:
                break;
        }
    };

    return (
        <JobPipelineContext.Provider
            value={{
                currentJob,
                talentPoolCandidates,
                coachClientEvaluationsCandidates,
                selectedCandidates,
                rejectedCandidates,
                activeTab,
                activeCandidateProfile,
                handleTabChange,
                handlePrevJob,
                handleNextJob,
                handleFavoriteToggle,
                handleMenuAction,
                handleOnDragEnd,
                handleActiveCandidateProfile,
            }}
        >
            {children}
        </JobPipelineContext.Provider>
    );
};