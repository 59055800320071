import { Box, Button, Divider, TextField, Typography, Link as MuiLink } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'styled-icons/bootstrap';
import { Add } from 'styled-icons/material';
import { ClientEvaluation, EvaluationData } from 'types/jobPipeline';

const ClientEvaluationComponent = ({ evaluationData }: { evaluationData: EvaluationData }) => {
    const [clientEvaluations, setClientEvaluations] = useState<ClientEvaluation[]>([]);
    const [showNewInterviewForm, setShowNewInterviewForm] = useState<boolean>(false);
    const [newInterviewTitle, setNewInterviewTitle] = useState<string>('');
    const [newInterviewDate, setNewInterviewDate] = useState<string>('');
    const [newInterviewComments, setNewInterviewComments] = useState<string>('');

    useEffect(() => {
        if (evaluationData?.clientEvaluation) {
            setClientEvaluations(evaluationData.clientEvaluation);
        }
    }, [evaluationData]);

    const handleAddInterview = () => {
        setShowNewInterviewForm(true);
    };

    const handleSaveNewInterview = () => {
        setClientEvaluations([
            ...clientEvaluations,
            {
                expertId: '',
                roundId: clientEvaluations.length + 1,
                title: newInterviewTitle,
                completedOn: new Date(newInterviewDate).getTime(),
                feedback: newInterviewComments,
                skipped: false,
            }
        ]);
        setShowNewInterviewForm(false);
        setNewInterviewTitle('');
        setNewInterviewDate('');
        setNewInterviewComments('');
    };

    const handleSkipInterview = (index: number) => {
        const updatedEvaluations = [...clientEvaluations];
        updatedEvaluations[index].skipped = true;
        setClientEvaluations(updatedEvaluations);
    };

    const handleSubmit = () => {
        console.log("Submit client evaluations: ", clientEvaluations);
    };

    return (
        <Box>
            {clientEvaluations.map((evaluation, index) => (
                <Box key={index} mb={2} p={2} sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{evaluation.title}</Typography>
                        <MuiLink
                            component="button"
                            variant="body2"
                            onClick={() => handleSkipInterview(index)}
                            sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer' }}
                        >
                            Skipped this interview
                        </MuiLink>
                    </Box>
                    <Box display="flex" alignItems="center" mt={2}>
                        <Box sx={{ fontSize: 20, mr: 1, color: '#757575' }}>
                            <Calendar />
                        </Box>
                        <TextField
                            label="Date of Interview"
                            type="date"
                            value={moment(evaluation.completedOn).format('YYYY-MM-DD')}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            sx={{ mb: 2 }}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>
                    <TextField
                        label="Comments"
                        value={evaluation.feedback}
                        multiline
                        fullWidth
                        rows={3}
                        variant="outlined"
                        sx={{ mb: 2 }}
                        InputProps={{ readOnly: true }}
                    />
                    <Divider sx={{ my: 2 }} />
                </Box>
            ))}

            {showNewInterviewForm && (
                <Box mb={2} p={2} sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}>
                    <TextField
                        label="Interview Title"
                        value={newInterviewTitle}
                        onChange={(e) => setNewInterviewTitle(e.target.value)}
                        fullWidth
                        size='small'
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Date of Interview"
                        type="date"
                        size='small'
                        value={newInterviewDate}
                        onChange={(e) => setNewInterviewDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Comments"
                        value={newInterviewComments}
                        onChange={(e) => setNewInterviewComments(e.target.value)}
                        size='small'
                        multiline
                        fullWidth
                        rows={3}
                        variant="outlined"
                    />
                </Box>
            )}

            <div className='d-flex align-items-center justify-content-end w-100'>
                {!showNewInterviewForm && (
                    <MuiLink
                        component="button"
                        variant="body2"
                        onClick={handleAddInterview}
                        sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                    >
                        <Add height={24} width={24} />
                        <span>Add Another Interview</span>
                    </MuiLink>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    size='small'
                    onClick={handleSubmit}
                    sx={{ ml: 2 }}
                >
                    Submit
                </Button>
            </div>
        </Box>
    );
};

export default ClientEvaluationComponent;