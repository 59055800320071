/** @jsxImportSource @emotion/react */
import React from 'react';
import { Tab, TabList, Tabs } from 'react-tabs';
import styled from 'styled-components';

const CustomTabs = styled(Tabs)`
  border-bottom: 2px solid #E0E0E0;
`;

const CustomTabList = styled(TabList)`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  border: none;
  background: #FFF;
`;

const CustomTab = styled(Tab) <{ isActive: boolean }>`
  padding: 16px 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${props => (props.isActive ? '#2979FF' : '#757575')};
  border-bottom: ${props => (props.isActive ? '3px solid #2979FF' : '3px solid transparent')};
  transition: color 0.3s, border-bottom 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #2979FF;
  }
`;

interface HeaderTabsProps {
    activeTab: string;
    onTabChange: (tab: string) => void;
}

const HeaderTabs: React.FC<HeaderTabsProps> = ({ activeTab, onTabChange }) => {
    return (
        <CustomTabs
            selectedIndex={activeTab === 'Active' ? 0 : 1}
            onSelect={index => onTabChange(index === 0 ? 'Active' : 'Closed')}
        >
            <CustomTabList>
                <CustomTab isActive={activeTab === 'Active'}>Active Jobs (20)</CustomTab>
                <CustomTab isActive={activeTab === 'Closed'}>Closed Jobs (103)</CustomTab>
            </CustomTabList>
        </CustomTabs>
    );
};

export default HeaderTabs;