import { Button, InputBase, TextField } from "@mui/material";
import { Plus } from '@styled-icons/bootstrap/Plus';
import { FieldArray, Form, Formik, FormikErrors, useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";
import { InterViewRounds } from "types/JobManagement";
import * as Yup from 'yup';

const StyledWrapper = styled.div`
`

const ValidationSchema = Yup.object({
    interviewRounds: Yup.array()
        .of(
            Yup.object().shape({
                title: Yup.string().required("Title is required"),
                criteria: Yup.string(),
                question: Yup.string(),
            }))
});

type IProps = {
    initialValues: { interviewRounds: InterViewRounds[] };
    handleSubmit: (values: { interviewRounds: InterViewRounds[]; }) => void;
    submitBtnRef: React.MutableRefObject<HTMLButtonElement>;
    handleFormChange: (values: { interviewRounds: InterViewRounds[]; }) => void;
}

const InterviewRounds = (props: IProps) => {

    const handleFormSubmit = (value: { interviewRounds: InterViewRounds[] }) => {
        props.handleSubmit(value);
    }

    return (
        <StyledWrapper>
            <span className="form-label">Client Interview Process: (Add all interview rounds)</span>
            <Formik
                initialValues={{ ...props.initialValues }}
                validationSchema={ValidationSchema}
                enableReinitialize
                onSubmit={(value) => handleFormSubmit(value)}
            >
                {() => (
                    <Form>
                        <InterviewRoundsForm
                            handleFormChange={props.handleFormChange}
                        />
                        <button type="submit" ref={props.submitBtnRef} className="invisible"></button>
                    </Form>
                )}
            </Formik>

        </StyledWrapper>
    )
}

const InterviewRoundsForm = (props: {
    handleFormChange: (values: { interviewRounds: InterViewRounds[]; }) => void;
}) => {

    const { values, errors, setFieldValue } = useFormikContext<{ interviewRounds: InterViewRounds[] }>();

    const handleAddInterviewRoundClick = (push: <X = any>(obj: X) => void) => {
        push({
            title: '',
            criteria: '',
            question: ''
        })
    }

    useEffect(() => {
        props.handleFormChange(values);
    }, [values])

    return (
        <FieldArray name="interviewRounds">
            {({ push, remove }) => (
                <>
                    {values.interviewRounds.map((round, idx) => {
                        return (
                            <div key={idx}>
                                <div className="d-flex mt-3">
                                    <span className="mr-3">Round {idx + 1}: </span>
                                    <div className=" flex-grow-1">
                                        <InputBase
                                            sx={{ flex: 1 }}
                                            placeholder="Title"
                                            className="input-element"
                                            name='title'
                                            value={round.title}
                                            onChange={(e) => setFieldValue(`interviewRounds.${idx}.title`, e.target.value)}
                                        />
                                        {
                                            errors?.interviewRounds?.[idx] &&
                                            typeof errors.interviewRounds[idx] !== 'string' &&
                                            (errors.interviewRounds[idx] as FormikErrors<{ title: string; criteria: string; question: string; }>).title && (
                                                <span className="text-danger error-font">
                                                    {(errors.interviewRounds[idx] as FormikErrors<{ title: string; criteria: string; question: string; }>)?.title}
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <span className="invisible" style={{ marginRight: '0.9rem' }}>Round 1:</span>
                                    <div className="flex-grow-1">
                                        <TextField
                                            variant='outlined'
                                            fullWidth
                                            multiline
                                            minRows={2}
                                            size='small'
                                            placeholder="Criteria (optional)"
                                            name='criteria'
                                            value={round.criteria}
                                            onChange={(e) => setFieldValue(`interviewRounds.${idx}.criteria`, e.target.value)}
                                        />
                                        <span className="text-muted">*Please provide the criteria for clearing this Round, if applicable.</span>
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <span className="invisible" style={{ marginRight: '0.9rem' }}>Round 1:</span>
                                    <div className="flex-grow-1">
                                        <TextField
                                            variant='outlined'
                                            fullWidth
                                            multiline
                                            minRows={2}
                                            size='small'
                                            placeholder="Question (optional)"
                                            name='question'
                                            value={round.question}
                                            onChange={(e) => setFieldValue(`interviewRounds.${idx}.question`, e.target.value)}
                                        />
                                        <span className="text-muted">*Add any sample question you'd like to address at this stage.</span>
                                    </div>
                                </div>
                                {values.interviewRounds.length !== 1 &&
                                    <div className="text-right mt-3">
                                        <Button variant="outlined" color="error" onClick={() => remove(idx)}>Delete round {idx + 1}</Button>
                                    </div>}
                            </div>
                        )
                    })}
                    <Button className="w-100 mt-3" variant="outlined" type="button" onClick={() => handleAddInterviewRoundClick(push)}><Plus width={'25px'} />Add another Round</Button>
                </>
            )}
        </FieldArray>
    )
}

export default InterviewRounds;