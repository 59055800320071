import { Box, Button, Divider, FormControlLabel, Radio, RadioGroup, Step, StepButton, StepContent, Stepper, TextField, Typography } from '@mui/material';
import { CircleDot } from "@styled-icons/fa-solid/CircleDot";
import { AwsUploader } from 'components/Uploader/AwsUploader';
import { useJobPipeline } from 'containers/JobPipeline/contexts/JobPipelineContext';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCandidateEvaluationStatus } from 'services/jobPipeline';
import { RootState } from 'store';
import styled from 'styled-components';
import { CheckCircleOutline } from 'styled-icons/material';
import { EvaluationData } from 'types/jobPipeline';
import ClientEvaluationComponent from './ClientEvaluationComponent';

const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  width: 24px;
  height: 24px;
  color: #4caf50;
`;

const StyledRadioButtonUnchecked = styled(CircleDot)`
  width: 24px;
  height: 24px;
  color: #757575;
`;

const CandidateEvaluationComponent: React.FC = () => {
    const { currentJob, activeCandidateProfile } = useJobPipeline();
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState<Set<number>>(new Set());
    const [evaluationData, setEvaluationData] = useState<EvaluationData>();
    const [offerExtended, setOfferExtended] = useState<string>('');
    const [offerAccepted, setOfferAccepted] = useState<string>('');
    const [selectionComments, setSelectionComments] = useState<string>('');
    const candidateId = activeCandidateProfile?.candidateId || '';
    const trackId = currentJob?.trackId || '';
    const attachmentsDir = `${candidateId}/Resumes/${trackId}`;

    useEffect(() => {
        if (candidateId && currentJob?.jobId && expertId) {
            const fetchEvaluationStatus = async () => {
                try {
                    const data = await getCandidateEvaluationStatus(expertId, currentJob.jobId, candidateId);
                    setEvaluationData(data);

                    if (data.selectionStatus) {
                        setOfferExtended(data.selectionStatus.haveExtendedOffer ? 'yes' : 'no');
                        setOfferAccepted(data.selectionStatus.offerStatus === 'OFFER_ACCEPTED' ? 'accepted' : 'rejected');
                        setSelectionComments(data.selectionStatus.feedback || '');
                    }

                    if (data.aiResumeEvaluation || data.resumeUrl) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 0]));
                    }

                    if (data.aiResumeEvaluation?.careerProgressionDetails) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 1]));
                    }

                    if (data.intentEvaluation) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 2]));
                    }

                    if (data.coachEvaluation) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 3]));
                    }

                } catch (error) {
                    console.error("Failed to fetch candidate evaluation status:", error);
                }
            };

            fetchEvaluationStatus();
        }
    }, [expertId, currentJob, candidateId]);

    const isStepComplete = (step: number) => completed.has(step);

    const handleImageUpload = (fileUrl: string) => {
        console.log(`File uploaded successfully: ${fileUrl}`);
        setCompleted(prevCompleted => new Set([...prevCompleted, 0]));
        setActiveStep(1);
    };

    const handleSelectionSubmit = () => { }

    if (!evaluationData) {
        return <Typography>Loading...</Typography>;
    }

    const steps = [
        {
            label: 'Upload Resume',
            description: (
                <Box>
                    {activeCandidateProfile?.resumeUrl ? (
                        <Typography variant="body2">
                            Resume already uploaded.{' '}
                            <a
                                href={activeCandidateProfile.resumeUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View Resume
                            </a>
                        </Typography>
                    ) : (
                        <AwsUploader
                            onUpload={handleImageUpload}
                            directory={attachmentsDir}
                            expertId={expertId}
                            allowAllUpload={true}
                        />
                    )}
                </Box>
            ),
        },
        {
            label: 'AI Evaluation',
            description: (
                <Box>
                    <Typography variant="body2" mb={2}>
                        {evaluationData.aiResumeEvaluation?.careerProgressionDetails}
                    </Typography>
                    <Box
                        sx={{
                            display: 'inline-block',
                            padding: '4px 12px',
                            borderRadius: '12px',
                            backgroundColor: '#3f51b5',
                            color: '#fff',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            mb: 2
                        }}
                    >
                        {evaluationData.aiResumeEvaluation?.careerProgress.replace('_', ' ')}
                    </Box>
                    <Divider sx={{ my: 2 }} />
                </Box>
            )
        },
        {
            label: 'Intent Evaluation',
            description: (
                <Box>
                    {evaluationData.intentEvaluation ? (
                        <Box>
                            <Box display="flex" alignItems="center" mb={1}>
                                {evaluationData.intentEvaluation.profileImgUrl ? (
                                    <img
                                        src={evaluationData.intentEvaluation.profileImgUrl}
                                        alt={evaluationData.intentEvaluation.expertName}
                                        style={{ borderRadius: '50%', marginRight: '10px', width: '40px', height: '40px' }}
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#3f51b5',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginRight: '10px',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {evaluationData.intentEvaluation.expertName
                                            .split(' ')
                                            .map(n => n[0])
                                            .join('')}
                                    </Box>
                                )}
                                <Box>
                                    <Typography variant="body1">{evaluationData.intentEvaluation.expertName}</Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {moment(evaluationData.intentEvaluation.completedOn).format('MMMM D, YYYY')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography variant="body2" mb={2}>
                                {evaluationData.intentEvaluation.expertComment}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'inline-block',
                                    padding: '4px 12px',
                                    borderRadius: '12px',
                                    backgroundColor: '#3f51b5',
                                    color: '#fff',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    mb: 2,
                                }}
                            >
                                {evaluationData.intentEvaluation.candidateMotivation.replace('_', ' ')}
                            </Box>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            The intent evaluation is currently under process. Please check back later.
                        </Typography>
                    )}
                </Box>
            ),
        },
        {
            label: 'Coach Evaluation',
            description: (
                <Box>
                    {evaluationData.coachEvaluation ? (
                        <Box>
                            <Box display="flex" alignItems="center" mb={1}>
                                {evaluationData.coachEvaluation.profileImgUrl ? (
                                    <img
                                        src={evaluationData.coachEvaluation.profileImgUrl}
                                        alt={evaluationData.coachEvaluation.expertName}
                                        style={{ borderRadius: '50%', marginRight: '10px', width: '40px', height: '40px' }}
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#3f51b5',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginRight: '10px',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {evaluationData.coachEvaluation.expertName
                                            .split(' ')
                                            .map(n => n[0])
                                            .join('')}
                                    </Box>
                                )}
                                <Box>
                                    <Typography variant="body1">
                                        {evaluationData.coachEvaluation.expertName}
                                        {evaluationData.coachEvaluation.coachEvalScore && (
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    padding: '2px 8px',
                                                    borderRadius: '8px',
                                                    backgroundColor: '#f0e68c',
                                                    color: '#333',
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    ml: 1,
                                                }}
                                            >
                                                Coach Eval Score: {evaluationData.coachEvaluation.coachEvalScore.toFixed(1)}
                                            </Box>
                                        )}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {moment(evaluationData.coachEvaluation.completedOn).format('MMMM D, YYYY')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography variant="body2" mb={2}>
                                {evaluationData.coachEvaluation.expertComment}
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            The client evaluation is currently under process. Please check back later.
                        </Typography>
                    )}
                </Box>
            ),
        },
        {
            label: 'Client Evaluation',
            description: <ClientEvaluationComponent evaluationData={evaluationData} />,
        },
        {
            label: 'Selection',
            description: (
                <Box>
                    <Typography variant="body2" mb={1}>Have you extended the offer?</Typography>
                    <RadioGroup
                        row
                        value={offerExtended}
                        onChange={(e) => setOfferExtended(e.target.value)}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                    <Typography variant="body2" mt={2} mb={1}>Did the Candidate accept the offer?</Typography>
                    <RadioGroup
                        row
                        value={offerAccepted}
                        onChange={(e) => setOfferAccepted(e.target.value)}
                    >
                        <FormControlLabel value="accepted" control={<Radio />} label="Offer Accepted" />
                        <FormControlLabel value="rejected" control={<Radio />} label="Offer Rejected" />
                    </RadioGroup>
                    <TextField
                        label="Add Comments, if Any"
                        fullWidth
                        multiline
                        rows={3}
                        value={selectionComments}
                        onChange={(e) => setSelectionComments(e.target.value)}
                        sx={{ mt: 2, mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        size='small'
                        onClick={handleSelectionSubmit}
                        sx={{ ml: 2 }}
                    >
                        Submit
                    </Button>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepButton
                            onClick={() => setActiveStep(index)}
                            icon={isStepComplete(index) ? <StyledCheckCircleOutline /> : <StyledRadioButtonUnchecked />}
                        >
                            <Typography variant="subtitle1">{step.label}</Typography>
                        </StepButton>
                        <StepContent>
                            {step.description}
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default CandidateEvaluationComponent;